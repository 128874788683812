import PropTypes from 'prop-types';

export const UPDATE_ANSWERS = 'Proms.QuestionToQuestion/UPDATE_ANSWERS';
export const START_PROM = 'Proms.QuestionToQuestion/START_PROM';
export const SUBMIT_QUESTION = 'Proms.QuestionToQuestion/SUBMIT_QUESTION';
export const ENQUEUE_FOR_SUBMISSION = 'Proms.QuestionToQuestion/ENQUEUE_FOR_SUBMISSION';
export const SUBMIT_PROM = 'Proms.QuestionToQuestion/SUBMIT_PROM';
export const RESET_PROM = 'Proms.QuestionToQuestion/RESET_PROM';
export const FETCH_SECTION = 'Proms.QuestionToQuestion/FETCH_SECTION';
export const START_OVER_PROM = 'Proms.QuestionToQuestion/START_OVER_PROM';
export const START_SUBMISSION = 'Proms.QuestionToQuestion/START_SUBMISSION';
export const SUBMIT_QUESTIONNAIRE = 'Proms.QuestionToQuestion/SUBMIT_QUESTIONNAIRE';
export const SKIP_QUESTIONNAIRE_RESPONSE = 'Proms.QuestionToQuestion/SKIP_QUESTIONNAIRE_RESPONSE';

export const QuestionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  key: PropTypes.string,
  title: PropTypes.string,
  widget: PropTypes.string,
  family: PropTypes.string,
  hint: PropTypes.string,
  block: PropTypes.string,
  event: PropTypes.string,
  exam: PropTypes.string,
  i18n: PropTypes.object,
  uiConfig: PropTypes.object,
  completed: PropTypes.bool,
  answer: PropTypes.shape({
    type: PropTypes.string,
    options: PropTypes.array,
    extra: PropTypes.object
  }),
  validation: PropTypes.shape({
    isValid: PropTypes.bool,
    errors: PropTypes.array
  }),
  isSubmittableOnChange: PropTypes.bool,
  submissionStatus: PropTypes.string,
  mandatory: PropTypes.bool
});

export const QUESTION_SUBMISSION_STATUS = Object.freeze({
  INITIAL: 'INITIAL',
  IN_QUEUE: 'IN_QUEUE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
});

export const VALIDATION_ERRORS = Object.freeze({
  REQUIRED: 'This field is required',
  INVALID_DATE: 'Invalid date',
  INVALID_NUMBER: 'Invalid number',
  INVALID_LIMIT: 'Invalid limit',
  INVALID_CHOICE: 'Invalid choice'
});

export const QUESTION_TYPE = Object.freeze({
  INSTRUCTION: 'instruction',
  RECOMMENDATION: 'recommendation',
  TRANSITION: 'transition',
  SECTION: 'section',
  AGREEMENT: 'agreement',
  RESUME: 'resume',
  FINISH: 'finish',
  QUESTION: 'question'
});
