import { datetoString, DEFAULT_DATE_FORMAT, moment } from 'utils/dates';
import ParamEngine from 'utils/ParamEngine';
import { ADD_TOAST } from '../constants/actionTypes';
import { SERVER_ERROR } from '../constants/messages';
import axios from '../utils/axios';

const { CancelToken } = axios;

class WebAppApi {
  static request = ({ method, url, data = undefined }) => dispatch =>
    axios({
      method,
      url,
      data
    })
      .then(response => response)
      .catch(request => {
        if (dispatch) {
          let error = SERVER_ERROR;

          if (request.response.data.errors) {
            error = {
              ...error,
              message: request.response.data.errors[0]
            };
          }

          dispatch({
            type: ADD_TOAST,
            payload: error
          });
        }
        return Promise.reject(request);
      });
  /**
   * Get last server answer stored for some question
   * @param {string} patientID
   * @param {number} diseaseID
   * @param {string} questionKey
   */
  static getServerAnswers = (patientID, diseaseID, questionKey) =>
    axios.get(`/api/patients/${patientID}/diseases/${diseaseID}/question/${questionKey}/answer`);

  /**
   * Get all patient disease that current patient has
   * @param {object} patient
   */
  static getPatientsDiseases = ({ diseases_url: URL }) => axios.get(URL);

  /**
   * Get all patients that professional can manage in institution. Allow search patients
   * @param {*} institution
   * @param {*} params
   * @param {*} receivedSource
   */
  static getPatientsList = (institution, params = {}, receivedSource = undefined) => {
    // join urls with params
    const source = receivedSource ?? axios.CancelToken.source();

    const url = new ParamEngine().buildUrl(`${institution.url}/patients/search`, params);
    const request = axios.get(url, { cancelToken: source.token });
    return { source, request };
  };

  static getCandidatesList = (institution, params = {}) => {
    const source = CancelToken.source();
    // join urls with params
    const url = new ParamEngine().buildUrl(`${institution.url}/candidates`, params);
    const request = axios.get(url, { cancelToken: source.token });
    return { source, request };
  };

  /**
   * Get institution care doctor with some categories by default: doctor and surgeon.
   * @param {string} institutionSlug
   * @param {string} diseaseSlug
   */
  static getCareDoctors = (institutionSlug, diseaseSlug) => {
    let baseUrl = `/api/institutions/${institutionSlug}/members?category=doctor&category=surgeon&page_size=100`;

    // If disease is provided add to filters
    if (diseaseSlug) {
      baseUrl += `&disease=${diseaseSlug}`;
    }
    return axios.get(baseUrl);
  };

  /**
   *
   * @param {string} institutionSlug
   * @param {string} professionalId
   * @param {object} patient
   */
  static createAnonymousPatient = (institutionSlug, professionalId, patient) =>
    axios.post(`/api/institutions/${institutionSlug}/members/${professionalId}/patients/new`, patient);

  /**
   * Verify if already exists one patient with same internal id for instituiton
   * @param {string} institutionSlug
   * @param {string} professionalId
   * @param {string} internalId
   */
  static validateInternalId = (institutionSlug, professionalId, internalId) =>
    axios.post(`/api/institutions/${institutionSlug}/members/${professionalId}/patients/internal_id`, {
      internal_id: internalId
    });

  /**
   *  Contact server fro retrive patient disease score. Filters allowed more control
   * @param {object} patient
   * @param {object} pdisease
   * @param {string} filters ex: "assessmet_id=1&limit=5"
   */
  static getPatientDiseaseScore = (patient, pdisease, filters = '') =>
    axios.get(`/api/patients/${patient.id}/diseases/${pdisease.id}/scores?${filters}`);

  /**
   *  Contact server for retrive patient assessments to fill. Filters allowed more control
   * @param {object} patientId patient uuid
   * @param {string} filters ex: "source=patient&disease=1"
   */
  static getTodoAssessments = (patientId, filters = '') => {
    let baseUrl = `/api/patients/${patientId}/timings`;
    if (Object.keys(filters).length) {
      baseUrl += `?${filters}`;
    }
    return axios.get(baseUrl);
  };
  /**
   *  Retrieve the last assessement of an patient
   * @param {object} patientId patient uuid
   * @param {number} patientDiseaseId patient disease Id
   * @param {string} type type of the last assessment we want to retrieve
   */
  static getPatientLastAssessment = (patientId, patientDiseaseId, type) =>
    axios.get(`/api/patients/${patientId}/diseases/${patientDiseaseId}/assessments?type=${type}`, {
      params: { only_last: true }
    });
  /**
   * Get institution information for some patient disease
   * @param {object} patient
   * @param {object} pdisease
   */
  static getPatientDiseaseInstitution = (patient, pdisease) =>
    axios.get(`/api/patients/${patient.id}/diseases/${pdisease.id}/institution`);

  /**
   * Update existent disease event for patient disease
   * @param {string} patientId patient uuid
   * @param {number} pdiseaseId patient disease id
   * @param {number} pdiseaseEventId patient disease event id
   * @param {object} data payload with answers
   */
  static updatePatientDiseaseEvent = ({ patientId, pdiseaseId, pdiseaseEventId, data }) =>
    axios.patch(`/api/patients/${patientId}/diseases/${pdiseaseId}/events/${pdiseaseEventId}`, data);

  /**
   * Create new event for patient disease
   * @param {string} patientId patient uuid
   * @param {number} pdiseaseId patient disease id
   * @param {object} data payload with answers
   */

  static createPatientDiseaseEvent = ({ patientId, pdiseaseId, data }) =>
    axios.post(`/api/patients/${patientId}/diseases/${pdiseaseId}/events`, data);

  /**
   * Gets generic form for some disease and event
   * @param {string} diseaseSlug ex: type-2-diabetes
   * @param {string} eventSlug ex: sugery-date
   */
  static getEventDiseaseForm = ({ diseaseSlug, eventSlug }) =>
    axios.get(`/api/diseases/${diseaseSlug}/events/${eventSlug}`);

  /**
   * Gets Patient disease event with form and answers
   * @param {string} patientId patient uuid
   * @param {number} pdiseaseId patient disease id
   * @param {number} pdiseaseEventId patient disease event id
   * @param {object} data payload with answers
   */
  static getPatientDiseaseEvent = ({ patientId, pdiseaseId, pdiseaseEventId }) =>
    axios.get(`/api/patients/${patientId}/diseases/${pdiseaseId}/events/${pdiseaseEventId}`);

  /**
   *  Contact server for retrive patient specific score. Filters allowed more control
   * @param {object} patient
   * @param {object} scoreID
   * @param {string} filters ex: "limit=5"
   */
  static getPatientScore = (patient, scoreID, filters = '') =>
    axios.get(`/api/patients/${patient.id}/scores/${scoreID}?${filters}`);

  /**
   *  Contact server for retrive all patient scores. Filters allowed more control
   * @param {object} patient
   * @param {string} filters ex: "limit=5"
   */
  static getPatientScores = (patient, filters = '') => axios.get(`/api/patients/${patient.id}/scores?${filters}`);

  /**
   *  Contact server for all patient coas answered that have scores.
   * @param {string} pid: patient id
   * @param {string} pdid: patient disease id
   */
  static getPatientClinicalOutcomes = (pid, pdid) =>
    axios.get(`/api/patients/${pid}/diseases/${pdid}/clinical-outcomes?summarize=1&type=prom&only_last_assessment=1`);

  /**
   *  Contact server for all coas statistics.
   * @param {string} pid: patient id
   * @param {string} pdid: patient disease id
   * @param {string} coaUid: coa uid
   */
  static getPatientClinicalOutcomesStatistics = (pid, pdid, coaUid) =>
    axios.get(`/api/patients/${pid}/diseases/${pdid}/clinical-outcomes/${coaUid}/stats`);

  /**
   *  Contact server for patients scores for a given coa.
   * @param {string} pid: patient id
   * @param {string} pdid: patient disease id
   * @param {string} coaUid: clinical outcome uid
   * @param {number} limit: limit for number of scores
   */
  static getPatientScoreForClinicalOutcome = (pid, pdid, coaUid, limit) => {
    let url = `/api/patients/${pid}/diseases/${pdid}/clinical-outcomes/${coaUid}/scores`;
    if (limit) {
      url += `?limit=${limit}`;
    }
    return axios.get(url);
  };

  /**
   *  Contact server for patients scores dimensions for a given coa.
   * @param {string} pid: patient id
   * @param {string} pdid: patient disease id
   * @param {string} coaUid: clinical outcome uid
   */
  static getPatientDimensionsForClinicalOutcome = (pid, pdid, coaUid) =>
    axios.get(`/api/patients/${pid}/diseases/${pdid}/clinical-outcomes/${coaUid}/dimensions?summarize=1`);

  /**
   * fectch myself information, normally this is the entry api for giver user information
   */
  static fetchMySelf = () => axios.get('/me');

  /**
   * Request /me information from health provider
   * @param {object} healthSpace
   */
  static fetchHealthSpaceMe = healthSpace => axios.get(`${healthSpace.url}/me`);

  /**
   * Get informaton about health space. Ex: Name, avatar, description
   * @param {object} healthSpace
   * @param {object} patient
   */
  static fetchHealthSpaceInstitutions = (healthSpace, patient) =>
    axios.get(`${healthSpace.url}/api/patients/${patient.id}/institutions`);

  /**
   *  Contact server for retrive all patient disease timings. Filters allowed more control
   * @param {object} patient
   * @param {object} pdisease
   * @param {string} filters ex: "limit=5, distinct=True"
   */
  static getPatientDiseaseTimings = (patient, pdisease, filters = '') =>
    axios.get(`/api/patients/${patient?.id || patient}/diseases/${pdisease?.id || pdisease}/timings?${filters}`);

  /**
   * Export clinical data for some institution
   * @param {string} institutionSlug
   */
  static exportClinicalData = institutionSlug => axios.get(`/export/institutions/${institutionSlug}`);

  /**
   * Get patient history readings
   * @param {*} institutionSlug
   * @param {*} patientID
   * @param {*} patientDiseaseID

   */
  static getPatientHistoryReading = (institutionSlug, patientID, patientDiseaseID) =>
    axios.get(
      `/api/institutions/${institutionSlug}/patients/${patientID}/diseases/${patientDiseaseID}/history-readings?excludes_level=uncategorized`
    );

  /**
   * Get all institutions Members
   * @param {*} institutionSlug
   */
  static getInstitutionsMembers = institutionSlug => axios.get(`/api/institutions/${institutionSlug}/members`);

  /**
   * Fetch patient reported symptoms
   * @param {*} patientID
   * @param {*} pdiseaseId
   * @param {*} filters
   */
  static getPatientReportedSymptoms = (patientID, pdiseaseId) =>
    axios.get(`/api/patients/${patientID}/diseases/${pdiseaseId}/symptoms`);

  /**
   * Fetch mandatory, optional and question keys that may change its mandatory property
   * @param {*} URL
   * @param {*} method
   * @param {*} answers
   */
  static updateMandatoryQuestions = (URL, method, answers) => {
    if (method === 'patch') {
      return axios.patch(`${URL}/mandatory-questions`, { answers });
    } else if (method === 'get') {
      return axios.get(`${URL}/mandatory-questions`);
    }
  };

  /**
   * Toggle patient featured in watchlist
   * @param {*} institutionSlug
   * @param {*} patientId
   * @param {*} method Options are PUT or DELETE
   */
  static toggleFeaturedPatient = (institutionSlug, patientId, method) =>
    axios({
      method,
      url: `/api/institutions/${institutionSlug}/patients/${patientId}/featured`
    });

  /**
   *  Go get the todo list for the institution. If a filter object is provided it creates a string with all the filters to use in the url
   * @param {string} institutionSlug
   * @param {object} filters
   */
  static getTodoList = (institutionSlug, filters, date) => {
    const source = CancelToken.source();

    const filterStatus = {
      open: {
        value: 'created',
        expression: `start_at_lt=${datetoString(date, 1)}&end_at_gtn=${datetoString(date, 1)}`
      },
      'not-open': {
        value: 'created',
        expression: `start_at_gt=${datetoString(date, 1)}`
      },
      'almost-closing': {
        value: 'created',
        expression: `end_at_gt=${datetoString(date, -1)}&end_at_lt=${datetoString(date, 9)}`
      },
      closed: {
        value: 'created',
        expression: `end_at_lt=${datetoString(date, 1)}`
      }
    };

    let cleanedFilters = { ...filters };

    if ('status' in filters) {
      cleanedFilters = {
        ...filters,
        status: { ...(filterStatus[filters.status.value] || filters.status) }
      };
    }

    // make api request, if request is canceled trow response
    const request = axios.get(
      `/api/institutions/${institutionSlug}/disease-timings?${new ParamEngine().buildFilters(cleanedFilters)}`,
      {
        cancelToken: source.token
      }
    );
    return [source, request];
  };

  /**
   * Fetch readings values of a patient disease measurement
   * @param {string} patientId
   * @param {string} diseaseId
   * @param {string} category
   * @param {string} codeMetrics
   * @param {string} filter
   * @param {string} beginDate
   * @param {string} endDate
   */
  static fetchTimeline = (patientId, diseaseId, category, codeMetrics, filter, beginDate, endDate) => {
    let BASE_URL = `/api/patients/${patientId}/diseases/${diseaseId}/readings/${category}?`;
    const nextDay = moment().add(1, 'day').format(DEFAULT_DATE_FORMAT); // next day because hours is 00:00
    if (codeMetrics) {
      BASE_URL += `codes=${codeMetrics}&`;
    }
    switch (filter) {
      case '7d':
        BASE_URL += `filter=measured_at,gte,${moment()
          .subtract(7, 'd')
          .format('YYYY-MM-DD')}&filter=measured_at,lte,${nextDay}`;
        break;
      case '14d':
        BASE_URL += `filter=measured_at,gte,${moment()
          .subtract(14, 'd')
          .format('YYYY-MM-DD')}&filter=measured_at,lte,${nextDay}`;
        break;
      case '30d':
        BASE_URL += `filter=measured_at,gte,${moment()
          .subtract(30, 'd')
          .format('YYYY-MM-DD')}&filter=measured_at,lte,${nextDay}`;
        break;
      case '90d':
        BASE_URL += `filter=measured_at,gte,${moment()
          .subtract(90, 'd')
          .format('YYYY-MM-DD')}&filter=measured_at,lte,${nextDay}`;
        break;
      case 'custom':
        BASE_URL += `filter=measured_at,gte,${beginDate}&filter=measured_at,lte,${moment(endDate)
          .add(1, 'day')
          .format(DEFAULT_DATE_FORMAT)}`;
        break;
      default:
        if (filter) {
          BASE_URL += filter;
        }
        break;
    }

    return axios.get(BASE_URL);
  };

  /**
   * Fetch readings ranges of a patient disease measurement
   * @param {string} patientId
   * @param {string} diseaseId
   * @param {string} categories
   */
  static fetchReadingsRanges = (patientId, diseaseId, categories) => {
    let BASE_URL = `/api/patients/${patientId}/diseases/${diseaseId}/readings-ranges?`;
    if (categories) {
      BASE_URL += `codes=${categories}`;
    }
    return axios.get(BASE_URL);
  };

  /**
   * Fetch the dashboards associated with that user
   */
  static fetchAnalyticsDashboards = () => axios.get(`/api/analytics/dashboard/me`);

  static fetchNextEpromUrl = (patientId, diseaseId, filters = undefined) => {
    let baseUrl = `/eprom/patients/${patientId}/diseases/${diseaseId}/assessments`;
    if (filters) {
      baseUrl += `?${filters}`;
    }
    return axios.post(baseUrl);
  };

  /**
   * IIAG gets Terms and conditions from patient's user
   * @param {string} patientId
   */
  static getIIAGPatientTermsAndConditions = patientId => axios.get(`/api/iiag/${patientId}/terms-and-conditions`);

  /**
   * IIAG updates Terms and conditions from patient's user
   * @param {string} patientId
   * @param {object} data payload with answer
   */
  static updateIIAGPatientTermsAndConditions = (patientId, data) =>
    axios.patch(`/api/iiag/${patientId}/terms-and-conditions`, data);

  /**
   * IIAG update Clinical Trials from patient's user / disease
   * @param {string} patientId
   * @param {string} diseaseId Patient disease id
   * @param {string} ctid Clinical Trial id
   * @param {string} agreed Agreed
   */
  static updateIIAGPatientClinicalTrial = (patientId, diseaseId, ctid, agreed) =>
    axios.put(`/api/iiag/${patientId}/diseases/${diseaseId}/clinical-trials/${ctid}`, { agreed });

  /**
   * Update Clinical Trials from patient's user / disease
   * @param {string} patientId
   * @param {string} diseaseId Patient disease id
   * @param {string} ctid Clinical Trial id
   * @param {string} agreed Agreed
   * @param {string} hsURL Healthspace url
   */
  static updatePatientClinicalTrial = (patientId, diseaseId, ctid, agreed, hsURL = '') =>
    axios.put(`${hsURL}/api/terms/patients/${patientId}/diseases/${diseaseId}/clinical-trials/${ctid}`, { agreed });

  /**
   * update user agreement
   * @param {object} data payload form data
   */
  static submitUserAgreement = data =>
    axios.patch('/users/agreement', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

  /**
   * Refer patient to doctor
   * @param {string} institutionSlug Institution slug
   * @param {string} professionalId Patient disease id
   * @param {string} institutionMemberId Institution member id
   */
  static submitPatientReferral = (institutionSlug, institutionMemberId, patientDiseaseId, note) =>
    axios.post(`/api/institutions/${institutionSlug}/professionals/${institutionMemberId}/referral-patients`, {
      patient_disease_id: patientDiseaseId,
      note
    });

  /**
   * Unsubscribe user from all communications
   * @param {string} userId User id
   */
  static unsubscribeUserCommunication = userId => axios.patch(`/users/${userId}/communications`, { subscribe: 0 });

  /**
   * Validate identity
   *  @param {string} identityField field being used to identify user
   *  @param {string} userId User id
   *  @param {string} value value to be checked
   *  @param {number} attemptNumber attempt number
   */
  static validateIdentity = (userId, identityField, value, attemptNumber) =>
    axios.post(`/users/${userId}/identity_check`, {
      identity_field: identityField,
      value,
      attempt_number: attemptNumber
    });

  /**
   * Get a patient available questionnaires
   *  @param {string} diseaseSlug slug of the needed disease
   */
  static getAvailableQuestionnaires = diseaseSlug =>
    axios.get(`/api/clinical-outcomes?disease=${diseaseSlug}&type=prom`);

  /**
   * Get a custom questionnaires from an institution disease
   *  @param {string} diseaseSlug slug of the needed disease
   *  @param {string} institutionSlug slug of the institution
   */
  static getAvailableCustomQuestionnaires = (diseaseSlug, institutionSlug) =>
    axios.get(`/customquests/?disease=${diseaseSlug}&institution=${institutionSlug}`);

  /**
   * Get a patient ongoing questionnaires; for this the status of the assessment should be uncompleted and type prom (type prom means that the source of the assessment is patient [if crom it would have been those whose source is clinician])
   *  @param {string} patientID patient Id
   *  @param {string} patientDiseaseID
   */
  static getOngoingQuestionnaires = (patientID, patientDiseaseID) => {
    const dateLimit = moment().subtract(48, 'h').format(DEFAULT_DATE_FORMAT);
    return axios.get(
      `/api/patients/${patientID}/diseases/${patientDiseaseID}/assessments?status=uncompleted&type=prom&started_at_gt=${dateLimit}`
    );
  };

  /**
   * Get a patient heartbalt results
   *  @param {string} patientID patient Id
   *  @param {string} patientDiseaseID
   */
  static fetchHeartbaltReadings = (patientID, patientDiseaseID) =>
    axios.get(`/api/patients/${patientID}/diseases/${patientDiseaseID}/readings/heartbalt?codes=heartbalt`);

  /**
   * Get patients for todoList
   * @param {*} institutionSlug
   * @param {*} type
   */
  static getTodoListItems = (institutionSlug, type) =>
    axios.get(`/api/institutions/${institutionSlug}/todo-list?type=${type}`);

  /**
   * Get feature preferences for member
   * @param {*} institutionSlug
   * @param {*} memberPk
   * @param {*} feature
   */
  static fetchFeaturePreferences = (institutionSlug, memberPk, feature) =>
    axios.get(`/api/institutions/${institutionSlug}/members/${memberPk}/preferences?feature=${feature}`);

  /**
   * Update feature member preferences
   * @param {*} institutionSlug
   * @param {*} memberPk
   * @param {*} feature
   * @param {*} newValue
   */
  static updateFeaturePreferences = (institutionSlug, memberPk, feature, newValue) =>
    axios.patch(`/api/institutions/${institutionSlug}/members/${memberPk}/preferences`, {
      [feature]: { ...newValue }
    });

  static events = {
    getPatientDiseaseEvent: WebAppApi.getPatientDiseaseEvent,
    getEventDiseaseForm: WebAppApi.getEventDiseaseForm,
    updatePatientDiseaseEvent: WebAppApi.updatePatientDiseaseEvent,
    createPatientDiseaseEvent: WebAppApi.createPatientDiseaseEvent
  };

  static user = {
    fetchMySelf: WebAppApi.fetchMySelf,
    fetchHealthSpaceMe: WebAppApi.fetchHealthSpaceInstitutions,
    submitUserAgreement: WebAppApi.submitUserAgreement,
    unsubscribeUserCommunication: WebAppApi.unsubscribeUserCommunication,
    validateIdentity: WebAppApi.validateIdentity
  };

  static patient = {
    getPatientsDiseases: WebAppApi.getPatientsDiseases,
    fetchHealthSpaceInstitutions: WebAppApi.fetchHealthSpaceInstitutions,
    getPatientDiseaseScore: WebAppApi.getPatientDiseaseScore,
    getPatientDiseaseInstitution: WebAppApi.getPatientDiseaseInstitution,
    getPatientScore: WebAppApi.getPatientScore,
    getPatientScores: WebAppApi.getPatientScores,
    getIIAGPatientTermsAndConditions: WebAppApi.getIIAGPatientTermsAndConditions,
    updateIIAGPatientTermsAndConditions: WebAppApi.updateIIAGPatientTermsAndConditions,
    submitPatientReferral: WebAppApi.submitPatientReferral,
    getPatientHistoryReading: WebAppApi.getPatientHistoryReading,
    getPatientLastAssessment: WebAppApi.getPatientLastAssessment,
    getPatientReportedSymptoms: WebAppApi.getPatientReportedSymptoms,
    getAvailableQuestionnaires: WebAppApi.getAvailableQuestionnaires,
    getAvailableCustomQuestionnaires: WebAppApi.getAvailableCustomQuestionnaires,
    getOngoingQuestionnaires: WebAppApi.getOngoingQuestionnaires,
    getPatientClinicalOutcomes: WebAppApi.getPatientClinicalOutcomes,
    getPatientScoreForClinicalOutcome: WebAppApi.getPatientScoreForClinicalOutcome,
    getPatientDimensionsForClinicalOutcome: WebAppApi.getPatientDimensionsForClinicalOutcome,
    getPatientClinicalOutcomesStatistics: WebAppApi.getPatientClinicalOutcomesStatistics,
    updateMandatoryQuestions: WebAppApi.updateMandatoryQuestions
  };

  static export = {
    clinicalData: WebAppApi.exportClinicalData
  };

  static institution = {
    getTodoList: WebAppApi.getTodoList,
    getPatientsList: WebAppApi.getPatientsList,
    getCareDoctors: WebAppApi.getCareDoctors,
    getCandidatesList: WebAppApi.getCandidatesList,
    toggleFeaturedPatient: WebAppApi.toggleFeaturedPatient,
    getTodoListItems: WebAppApi.getTodoListItems,
    fetchFeaturePreferences: WebAppApi.fetchFeaturePreferences,
    updateFeaturePreferences: WebAppApi.updateFeaturePreferences
  };

  static measurements = {
    fetchTimeline: WebAppApi.fetchTimeline,
    fetchReadingsRanges: WebAppApi.fetchReadingsRanges
  };

  static proms = {
    fetchNextEpromUrl: WebAppApi.fetchNextEpromUrl
  };

  static heartbalt = {
    fetchHeartbaltReadings: WebAppApi.fetchHeartbaltReadings
  };
}

export default WebAppApi;
