const ICONS_MAP = {
  ultrasound: 'ultrasound',
  'lab-work': 'lab'
};

const getIcon = key => ICONS_MAP[key] || '';

export class Exam {
  constructor(data) {
    const [exam] = data.exams;

    this.id = exam.uid;
    this.internalId = `${exam.uid}-exam`;
    this.name = data.name;
    this.date = data.date;
    this.uid = exam.uid;
    this.form = [];
    this.answers = { ...exam.answers };
    this.primary = true; // TODO: CHECK THIS DYNAMIC
    this.source = 'exam';
    this.items = exam.patient_exams;
    this.status = 'open';
    this.title = data.name;
    this.iconName = getIcon(exam.uid);
  }
}

export class ExamPatientItem {
  constructor(data, exam, category) {
    let questionnaire = {};

    if (exam.form) {
      questionnaire = exam.form.reduce((prev, question) => {
        let choices = [];
        if (question.answer_options && Object.keys(question.answer_options).indexOf('options') >= 0) {
          choices = [...question.answer_options.options];
        }
        prev[question.key] = {
          key: question.key,
          type: 'question',
          title: question.title,
          mandatory: question.mandatory,
          properties: {
            widget: question.widget,
            answer_type: question.answer_type,
            answer_extra: {
              ...question.answer_extra,
              readOnly: question.answer_extra.read_only,
              columns: question.answer_extra.questions ? [...question.answer_extra.questions] : []
            },
            choices
          }
        };
        return prev;
      }, {});
    }

    this.category = category.slug;
    this.id = data.uid;
    this.internalId = `${data.uid}-exam`;
    this.name = exam.name;
    this.uid = data.uid;
    this.questionnaire = questionnaire;
    this.answers = { ...data.answers };
    this.source = 'exam';
    this.title = exam.name;
    this.completed_at = data.date;
    this.date = data.date;
    this.can_start = true;
  }
}
