import { buildQuestionnaireReader } from 'Cros/utils/reader';
import {
  ADD_EMBED_ITEM,
  APPLY_GO_TO,
  ASSESSMENTS_REVIEWS,
  CHANGE_EMBED_ANSWER,
  CHANGE_SELECTED_EXAM,
  CHANGE_SELECTED_REVIEW,
  CRO_CHANGE_EXTRA_QUESTION_VALUE,
  CRO_CHANGE_QUESTION_OPTIONS,
  CRO_CHANGE_QUESTION_VALUE,
  CRO_SET_GOTO,
  CRO_UPDATE_MANDATORIES,
  CRO_VALIDATION_ERROR,
  EXAM_CHANGE_QUESTION_VALUE,
  FETCHED_EMBED_ITEMS,
  FETCHED_EMBED_SCHEMA,
  FETCH_EXAMS_LIST_SUCCESS,
  REMOVE_EMBED_ITEM,
  RESET_SELECTED_EXAM,
  RESET_SELECTED_REVIEW,
  SET_QUESTION_KEYS,
  TOGGLE_DELETE_EMBED_ITEM,
  UPDATE_CRO_ASSESSMENT
} from 'constants/actionTypes';
import { get, isEqual } from 'lodash';
import { ExamPatientItem } from '../models/Exam';

export const initialState = {
  items: {},
  loading: false,
  event: undefined,
  selected: {},
  selectedExam: {},
  answers: {},
  questionKeys: [],
  registry: [],
  errors: {},
  exams: [],
  goTo: {
    exams: {
      id: undefined
    },
    assessments: {
      id: undefined
    }
  }
};

const hasDiffs = (questionnaire, currentAnswers, changedTimingAnswers) =>
  Object.keys(questionnaire)
    .filter(key => get(questionnaire[key], 'properties.answer_type', '') !== 'auto')
    .some(key => {
      if (!currentAnswers[key]) {
        return true;
      }

      if (!isEqual(currentAnswers[key], changedTimingAnswers[key])) {
        const orderTarget = currentAnswers[key].map(a => JSON.stringify(a)).sort();
        const orderSource = changedTimingAnswers[key].map(a => JSON.stringify(a)).sort();
        const equal = isEqual(orderTarget, orderSource);
        return !equal;
      }
      return false;
    });

const retrieveUpdatedQuestionEmbed = (state, question) => ({
  ...state,
  selected: {
    ...state.selected,
    questionnaire: {
      ...state.selected.questionnaire,
      [question.key]: {
        ...state.selected.questionnaire[question.key],
        ...question
      }
    }
  }
});

const _getReviewByTimingID = (id, state) => {
  let selected;
  let event;

  const eventKeys = Object.keys(state.items);
  for (let i = 0; i < eventKeys.length; i += 1) {
    for (let j = 0; j < state.items[eventKeys[i]].timings.length; j += 1) {
      if (state.items[eventKeys[i]].timings[j].id === Number(id)) {
        event = eventKeys[i];
        selected = state.items[eventKeys[i]].timings[j];
        break;
      }
    }
  }
  return [selected, event];
};

export const croInitialization = (state, answers, questions) => {
  const [key] = Object.keys(questions);

  const items = Object.keys(questions).reduce((p, eventKey) => {
    const { timings } = questions[eventKey];
    const item = {
      ...questions[eventKey],
      timings: timings.map(t => ({
        ...t,
        resume: t.source === 'patient' ? buildQuestionnaireReader(t.questionnaire.nodes, t.answers) : []
      }))
    };
    p[eventKey] = item;
    return p;
  }, {});

  return {
    ...state,
    event: key,
    items,
    selected: Object.keys(items).length > 0 ? items[key].timings[0] : undefined,
    answers,
    errors: {}
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSESSMENTS_REVIEWS: {
      return croInitialization(state, action.payload.answers, action.payload.questions);
    }

    case CRO_VALIDATION_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }

    case RESET_SELECTED_REVIEW: {
      return {
        ...state,
        selected: {}
      };
    }

    case CHANGE_SELECTED_REVIEW: {
      let [selected, event] = _getReviewByTimingID(action.payload, state);
      if (!selected) {
        ({ selected, event } = state);
      }

      return {
        ...state,
        selected,
        event,
        errors: {},
        goTo: {
          ...state.goTo,
          assessments: {
            id: selected.id
          }
        }
      };
    }

    case CRO_CHANGE_QUESTION_OPTIONS: {
      const { key, parentKey, options } = action.payload;

      if (!parentKey) {
        const newSelected = {
          ...state.selected,
          questionnaire: {
            ...state.selected.questionnaire,
            [key]: {
              ...state.selected.questionnaire[key],
              properties: {
                ...state.selected.questionnaire[key]?.properties,
                choices: options
              }
            }
          }
        };

        return {
          ...state,
          items: {
            ...state.items,
            [state.event]: {
              ...state.items[state.event],
              timings: state.items[state.event].timings.map(t => {
                if (t.key === state.selected.key) {
                  return newSelected;
                }
                return t;
              })
            }
          },
          selected: newSelected
        };
      }

      const selected = {
        ...state.selected,
        questionnaire: {
          ...state.selected.questionnaire,
          [parentKey]: {
            ...state.selected.questionnaire[parentKey],
            properties: {
              ...state.selected.questionnaire[parentKey].properties,
              answer_extra: {
                ...state.selected.questionnaire[parentKey].properties.answer_extra,
                columns: state.selected.questionnaire[parentKey].properties.answer_extra.columns.map(c => {
                  if (c.key === key) {
                    return {
                      ...c,
                      properties: {
                        ...c.properties,
                        choices: options
                      }
                    };
                  }
                  return c;
                })
              }
            }
          }
        }
      };

      return {
        ...state,
        items: {
          ...state.items,
          [state.event]: {
            ...state.items[state.event],
            timings: state.items[state.event].timings.map(t => {
              if (t.key === state.selected.key) {
                return selected;
              }
              return t;
            })
          }
        },
        selected
      };
    }

    case CRO_CHANGE_QUESTION_VALUE: {
      let answers = {
        ...state.answers
      };
      const timingKey = state.selected.key;
      let changedTimingAnswers = {
        ...state.answers[state.event][timingKey]
      };

      const hasQuestion = Object.keys(state.selected.questionnaire).includes(action.payload.question);
      if (hasQuestion) {
        changedTimingAnswers = {
          ...state.answers[state.event][timingKey],
          [action.payload.question]: action.payload.data ? [...action.payload.data] : []
        };
        answers = {
          ...state.answers,
          [state.event]: {
            ...state.answers[state.event],
            [timingKey]: changedTimingAnswers
          }
        };
      }

      return {
        ...state,
        answers: {
          ...answers
        },
        selected: {
          ...state.selected,
          toutched: hasDiffs(state.selected.questionnaire, state.selected.answers, changedTimingAnswers)
        }
      };
    }

    case SET_QUESTION_KEYS: {
      return {
        ...state,
        questionKeys: action.payload
      };
    }

    case CRO_UPDATE_MANDATORIES: {
      const { mandatoryQuestions, optionalQuestions } = action.payload;

      const updatedQuestionnaire = { ...state.selected.questionnaire };

      const updateQuestion = (keyToUpdate, mandatory) => {
        const item = Object.keys(updatedQuestionnaire).find(key => key === keyToUpdate);
        if (item) {
          updatedQuestionnaire[item].mandatory = mandatory;
        }
      };
      if (mandatoryQuestions.length) {
        mandatoryQuestions.map(question => updateQuestion(question, true));
      }

      if (optionalQuestions.length) {
        optionalQuestions.map(question => updateQuestion(question, false));
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          questionnaire: updatedQuestionnaire
        }
      };
    }

    case CRO_CHANGE_EXTRA_QUESTION_VALUE: {
      let answers = {
        ...state.answers
      };
      const timingKey = state.selected.key;
      let changedTimingAnswers = {
        ...state.answers[state.event][timingKey]
      };

      const hasQuestion = Object.keys(state.selected.questionnaire).includes(action.payload.question);
      if (hasQuestion) {
        changedTimingAnswers = {
          ...state.answers[state.event][timingKey],
          [action.payload.question]: action.payload.data
            ? state.answers[state.event][timingKey][action.payload.question].map(option => {
                if (option.choice === action.payload.data.choice) {
                  return action.payload.data;
                }
                return option;
              })
            : []
        };
        answers = {
          ...state.answers,
          [state.event]: {
            ...state.answers[state.event],
            [timingKey]: changedTimingAnswers
          }
        };
      }

      return {
        ...state,
        answers: {
          ...answers
        },
        selected: {
          ...state.selected,
          toutched: hasDiffs(state.selected.questionnaire, state.selected.answers, changedTimingAnswers)
        }
      };
    }

    case UPDATE_CRO_ASSESSMENT: {
      const answers = action.payload.answers ? action.payload.answers : {};

      const event = {
        ...state.items[state.event],
        timings: [
          ...state.items[state.event].timings.map(i => {
            if (i.id === action.payload.id) {
              return {
                ...i,
                answers,
                started_at: action.payload.started_at,
                completed_at: action.payload.completed_at,
                can_start: action.payload.can_start,
                status: action.payload.status
              };
            }
            return i;
          })
        ]
      };

      return {
        ...state,
        errors: {},
        items: {
          ...state.items,
          [state.event]: event
        },
        selected: {
          ...state.selected,
          toutched: false,
          answers,
          completed_at: action.payload.completed_at,
          can_start: action.payload.can_start,
          status: action.payload.status
        }
      };
    }

    case CHANGE_SELECTED_EXAM: {
      return {
        ...state,
        selectedExam: state.exams.find(e => e.id === action.payload.id),
        errors: {},
        goTo: {
          ...state.goTo,
          exams: {
            id: action.payload.id
          }
        }
      };
    }

    case RESET_SELECTED_EXAM: {
      return {
        ...state,
        selectedExam: {},
        questionKeys: []
      };
    }

    case FETCH_EXAMS_LIST_SUCCESS: {
      return {
        ...state,
        exams: action.payload.reduce((p, category) => {
          const items = category.exams
            .map(exam => exam.patient_exams.map(item => new ExamPatientItem(item, exam, category)).flat())
            .flat();
          return [...p, ...items];
        }, [])
      };
    }

    case EXAM_CHANGE_QUESTION_VALUE: {
      const { question, data } = action.payload;

      const newState = {
        ...state,
        exams: state.exams.map(e => {
          if (e.id === state.selectedExam.id) {
            return {
              ...e,
              answers: {
                ...e.answers,
                [question]: data
              }
            };
          }
          return e;
        }),
        selectedExam: {
          ...state.selectedExam,
          answers: {
            ...state.selectedExam.answers,
            [question]: data
          }
        }
      };
      return newState;
    }

    case CRO_SET_GOTO: {
      const { tab, type } = action.payload;
      const { id } = action.payload;
      // set some id and type for internal redirect
      return {
        ...state,
        goTo: {
          ...state.goTo,
          [tab]: {
            id,
            type
          }
        }
      };
    }

    case APPLY_GO_TO: {
      const { location, fallbackID } = action.payload;

      let newSelected;
      let newSelectedExam;
      let newEvent;

      // if redirect is for assessments
      if (location === 'assessments') {
        // if dont have any :id go to first event and first timing
        if (!state.goTo.assessments.id && !fallbackID) {
          const [key] = Object.keys(state.items);
          newSelected = Object.keys(state.items).length > 0 ? state.items[key].timings[0] : undefined;
          newEvent = key;
        } else {
          // try with url :id param.
          [newSelected, newEvent] = _getReviewByTimingID(fallbackID, state);
          // check stored goTo if has :id. Try get correct timing
          if (!newSelected && state.goTo.assessments.id) {
            // when we create an event in toolbar the return id is a event_disease id. So we use :type to check that
            if (state.goTo.assessments.type === 'event') {
              const k = Object.keys(state.items).find(key => state.items[key].id === state.goTo.assessments.id);
              if (k) {
                newEvent = k;
                [newSelected] = state.items[k].timings;
              }
            } else if (state.goTo.assessments.type === 'treatment') {
              const k = Object.keys(state.items).find(
                key => state.items[key].disease_treatment === state.goTo.assessments.id
              );
              if (k) {
                newEvent = k;
                [newSelected] = state.items[newEvent].timings;
              }
            } else {
              // try by timing id. The common way.
              [newSelected, newEvent] = _getReviewByTimingID(state.goTo.assessments.id, state);
            }
          }
        }
      } else if (location === 'exams') {
        // check if exam id exist. If yes tries to get. If not uses the first in list
        newSelectedExam = state.exams.find(e => e.uid === state.goTo.exams.id);
        if (!newSelectedExam) {
          newSelectedExam = state.exams.length > 0 ? state.exams[0] : undefined;
        }
      }

      return {
        ...state,
        selected: newSelected || state.selected,
        event: newEvent || state.event,
        selectedExam: newSelectedExam || state.selectedExam
      };
    }

    case FETCHED_EMBED_SCHEMA: {
      const { question, data } = action.payload;
      question.setSchema(data);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    case FETCHED_EMBED_ITEMS: {
      const { question, data } = action.payload;
      question.setItems(data);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    case CHANGE_EMBED_ANSWER: {
      const { question, item, answer } = action.payload;
      question.changeAnswer(item, answer);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    case TOGGLE_DELETE_EMBED_ITEM: {
      const { question, item } = action.payload;
      question.toggleDelete(item);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    case REMOVE_EMBED_ITEM: {
      const { question, item } = action.payload;
      question.removeItem(item);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    case ADD_EMBED_ITEM: {
      const { question } = action.payload;
      const { answers } = question.items.new;
      question.addNewItem(answers);
      return retrieveUpdatedQuestionEmbed(state, question);
    }

    default:
      return state;
  }
}
