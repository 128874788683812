import { get } from 'lodash';
import { moment } from './dates';

class ParamEngine {
  DEFAULT_PARAMS = {
    page: 1
  };

  getParams = (url = '') => {
    const searchParams = url.split('?');
    if (searchParams.length < 2) {
      return {};
    }
    const paramsArray = searchParams.pop().split('&');
    const paramsSplitArray = paramsArray.map(param => param.split('='));
    const paramsObject = paramsSplitArray.reduce((paramList, param) => ({ ...paramList, [param[0]]: param[1] }), {});
    return paramsObject;
  };

  buildDateRangeFilters(filters, n, p) {
    const startVal = get(filters[n], 'value.start_at');
    const endVal = get(filters[n], 'value.end_at');
    // when startVal and endVal is empty just return the previous string
    if (!startVal && !endVal) {
      return p;
    }

    const startDate = startVal && moment(startVal).format('YYYY-MM-DD');
    const endDate = endVal && moment(endVal).format('YYYY-MM-DD');

    const nextAppointmentGt = startDate ? `next_appointment_at_gt=${startDate}` : '';
    const nextAppointmentLt = endDate ? `next_appointment_at_lt=${endDate}` : '';

    return `${p}${nextAppointmentGt}${nextAppointmentGt && nextAppointmentLt ? '&' : ''}${nextAppointmentLt}&`;
  }

  buildUrlParametersFromArray = (paramName, values) => values.reduce((prev, val) => `${prev}${paramName}=${val}&`, '');

  buildSingleValue = (p, n, value, expression) => {
    if (value === 'all') {
      return p;
    }
    return expression ? `${p}${n}=${value}&${expression}&` : `${p}${n}=${value}&`;
  };

  buildMultiValue = (p, n, value, isAllSelected, filters) => {
    if (isAllSelected) {
      return p;
    }
    if (n === 'next_appointment_at') {
      return this.buildDateRangeFilters(filters, n, p);
    }
    return `${p}${this.buildUrlParametersFromArray(n, value)}`;
  };

  buildFilterString = (p, n, filter, filters) => {
    if (filter && !Object.keys(filter).includes('value')) {
      return `${p}${n}=${encodeURIComponent(filter)}&`;
    }
    if (!get(filter, 'value')) {
      return p;
    }

    const { expression, value, isAllSelected } = filter;

    if (typeof value === 'object') {
      return this.buildMultiValue(p, n, value, isAllSelected, filters);
    }
    return this.buildSingleValue(p, n, value, expression, filters);
  };

  buildFilters = (filters = {}) => {
    if (!Object.keys(filters).length) {
      return '';
    }

    const filtersString = Object.keys(filters).reduce((p, n) => this.buildFilterString(p, n, filters[n], filters), '');

    // removes last '&' from newUrl
    return filtersString.slice(0, -1);
  };

  buildUrl = (baseUrl, params = {}) => {
    if (!Object.keys(params).length) {
      return baseUrl;
    }

    const hasParams = baseUrl.split('?').length > 1;
    const newUrl = !hasParams ? `${baseUrl}?` : baseUrl;

    return `${newUrl}${this.buildFilters(params)}`;
  };

  areParamsEqual = (params1 = {}, params2 = {}) => JSON.stringify(params1) === JSON.stringify(params2);
}

export default ParamEngine;
