import { get } from 'lodash';
import { getTrans } from 'utils/translations';

export const buildQuestionnaireReader = (nodes, answers) =>
  Object.keys(nodes)
    .filter(key => {
      const node = nodes[key];
      return answers[node.question.key] || answers[node.id];
    })
    .map((key, index) => {
      const node = nodes[key];
      const answer = answers[node.question.key] || answers[node.id];
      let response = [];

      if (node.question.answer_options) {
        response = node.question.answer_options.options.reduce((p, o) => {
          const selected = answer.find(a => `${a.choice}` === `${o.key}`);
          if (selected) {
            let questionAnswer = `${getTrans(o, 'title', window.i18next.language)}`;
            if (get(o, 'extra.widget')) {
              questionAnswer = `${questionAnswer}${`, ${selected.value}` || ''}`;
            }
            p.push(questionAnswer);
          }
          return p;
        }, []);
      } else if (answer.filter(a => a.value !== undefined || a.value !== null).length > 0) {
        response = answer.filter(a => a.value !== undefined || a.value !== null).map(a => a.value);
      }

      return {
        question: index + 1,
        title: getTrans(node.question, 'title', window.i18next.language),
        type: node.question.widget,
        answer: response.length > 0 ? response.join(', ') : undefined
      };
    });
